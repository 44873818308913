import { Injectable } from '@angular/core';
import { apiColletions } from "../constants/api_collection";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private _api: ApiService) { }

  getLanguageList(): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.get({ url: apiColletions.get_language_list}).then((res) => {

          if (res.success) {
            resolve(res.data)
          } else {
            rejects(null)
          }
        })
      } catch (error) {
        resolve(null);
      }
    })
  }

  addLanguage(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.add_new_language, parameters }).then((response) => {
          if (response.success) {
            resolve(response);
          } else {
            resolve(null);
          }
        })
      } catch (err) {
        resolve(null);
      }
    })
  }

  editLanguage(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.edit_language, parameters }).then((response) => {
          if (response.success) {
            resolve(response);
          } else {
            resolve(null);
          }
        })
      } catch (err) {
        resolve(null);
      }
    })
  }

  deleteLanguage(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.delete_language, parameters }).then((response) => {
          if (response.success) {
            resolve(response);
          } else {
            resolve(null);
          }
        })
      } catch (err) {
        resolve(null);
      }
    })
  }

}
