import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsService {

    constructor() { }

    trackingId = environment.GOOGLE_ANALYTICS_TRACKING_ID;

    // Dynamically inject the Google Analytics script
    public loadGoogleAnalytics(): void {
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${this.trackingId}`;
        document.head.appendChild(script);

        // Initialize gtag after script is loaded
        script.onload = () => {
            this.initializeGoogleAnalytics();
        };
    }

    gtag(...args: any[]): void {
        (window as any).dataLayer.push(args);
    }

    private initializeGoogleAnalytics(): void {
        (window as any).dataLayer = (window as any).dataLayer || [];
        this.gtag('js', new Date());
        this.gtag('config', this.trackingId);
    }

    // Track page views
    public trackPageView(url: string): void {
        this.gtag('config', this.trackingId, {
            'page_path': url
        });
    }
}
