import { Injectable, NgZone, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { DATE_FORMAT, DEFAULT_IMAGE,PER_PAGE_LIST,USERS_PER_PAGE_LIST } from "../../app/constants/constants";
import { TranslateService } from "@ngx-translate/core";
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import * as moment from 'moment-timezone';
@Injectable({
    providedIn: 'root'
})

export class Helper {

    public image_url = environment.IMAGE_URL;
    public DEFAULT_IMAGE = DEFAULT_IMAGE;
    public DATE_FORMAT = DATE_FORMAT;
    public PER_PAGE_LIST = PER_PAGE_LIST;
    public USERS_PER_PAGE_LIST = USERS_PER_PAGE_LIST;
    selected_id = '';
    history_type = '';
    type: any;
    type_name: any;
    maxlength: number = 6;
    to_fixed_number: number = 2;
    uploadFile = ["image/jpeg", "image/jpg", "image/png"];
    uploadDocFile = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
    timezone: any;
    timelocal: any;
    public timeZone = new BehaviorSubject<any>(null);
    display_date_timezone = this.timeZone.asObservable();
    moment = moment;
    public created_at = new BehaviorSubject<any>(null);
    created_date = this.created_at.asObservable();

    public user_details = JSON.parse(localStorage.getItem('userData'));

    token: any;

    constructor(public http: HttpClient,
        public _route: Router,
        public trans: TranslateService,
        public ngZone: NgZone,
        @Inject(DOCUMENT) private _documentRef: any,) { }


    isUpadtedlocalStorage() {
        this.user_details = JSON.parse(localStorage.getItem('userData'));
    }
    phone_number_validation(evt) {
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57) || charCode === 101) {
            return false;
        }
        return true;
    }

    decimalNum_validation(evt, value = 0) {
        if (evt.key === '.' && value != null && (value.toString().indexOf('.') === value.toString().lastIndexOf('.'))) {
            return true;
        }
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            if (charCode == 46) {
                return true;
            }
            else {
                return false;
            }
        }
        return true;
    }

    number_validation(evt, value = 0) {
        if (evt.key === '.' && value != null && (value.toString().indexOf('.') === value.toString().lastIndexOf('.'))) {
            return false;
        }
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            if (charCode == 46) {
                return true;
            }
            else {
                return false;
            }
        }
        return true;
    }
    keyUpDown(evt) {
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if (charCode == 38 || charCode == 40 || evt.key == 'ArrowUp' || evt.key == 'ArrowDown') {
            return false;
        }
    }


    space_validation(evt) {
        if (evt.code == "Space" && evt.target.value.length < 1) {
            return false;
        }
        return true
    }
    nospace_validation(evt) {
        if (evt.code == "Space") {
            return false;
        }
        return true
    }

    special_char_validation_and_space_validation(event) {
        if (event.code == "Space" && (event.target.value.length < 1 || event.target.value)) {
            return false;
        } else {
            var k;
            k = event.charCode;
            return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
        }
    }

    check_email(email) {
        var email_validation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!email_validation.test(email)) {
            return false;
        }
        return true;
    }

    get generate_new_uuid(): string {
        return 'xxxxxxxx-xxxx-xxx-xxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    get uuid(): string {
        return 'xxxxxxxx-xxxx-xxx-xxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    loadGoogleScript(url) {
        return new Promise((resolve, reject) => {
            if (!document.querySelector('script[src="' + url + '"]')) {
                const script = this._documentRef.createElement('script');
                script.type = 'text/javascript';
                script.src = url;
                script.text = ``;
                script.async = true;
                script.defer = true;
                script.onload = resolve;
                script.onerror = reject;
                document.body.appendChild(script);
            } else {
                resolve(true);
            }
        })
    }
    downloadImage(url: string, fileName: string) {
        const a: any = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.style = 'display: none';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    };
    downloadUrl(url: string) {
        return this.http.get(url, { responseType: 'blob' }).pipe(switchMap(response => this.readFile(response)));
    }
    private readFile(blob: Blob): Observable<string> {
        return Observable.create(obs => {
            const reader = new FileReader();
            reader.onerror = err => obs.error(err);
            reader.onabort = err => obs.error(err);
            reader.onload = () => obs.next(reader.result);
            reader.onloadend = () => obs.complete();
            return reader.readAsDataURL(blob);
        });
    }
    downloadFile(res: any) {
        this.http.get(res, { responseType: 'blob' as 'blob' }).subscribe(fileData => {
            const blob: any = new Blob([fileData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            let filename = res.split('xlsheet/')
            let link = document.createElement("a");

            if (link.download !== undefined) {
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename[1]);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
        );
    }
    // getWeekDay(date,index) {
    //     date = new Date(date);
    //     let today_date = new Date();

    //     if(index == 0){
    //         var day = date.getDay(),
    //         // diff = date.getDate() - day + (day == 0 ? -6 : 1); // week first day is monday
    //         diff = date.getDate() - day; // week first day is sunday
    //         new Date(date.setDate(diff));
    //     }
        
    //     if(index != 0){
    //         var first = date.getDate() + 1;
    //     }else{
    //         var first = date.getDate();
    //     }
    //     var start_date: any = new Date(date.setDate(first))

    //     if (start_date.getTime() < today_date.getTime()) {
    //         var last;
    //         if(first>31){
    //           first=0;
    //           last= 6;
    //         }else{
    //          last = first + 6;
    //         }
    //         var end_date: any = new Date(date.setDate(last))
    //         // if (first <= 0) {
    //         //     end_date = new Date(date.setDate(last))
    //         //     end_date = new Date(new Date(end_date).setMonth(new Date(end_date).getMonth() + 1))
    //         //     console.log(end_date);

    //         // } else {
    //         //     end_date = new Date(date.setDate(last))
    //         // }
    //         // start_date = new Date(new Date(start_date).setHours(0,0,0,0)).toUTCString()
    //         // end_date = new Date(new Date(end_date).setHours(0,0,0,0)).toUTCString()
    //         return [start_date, end_date];
    //     }

    // }

    getWeeks(startDate) {
        // Convert the start date to a JavaScript Date object
        const start = new Date(startDate);
        const today = new Date();

        // Adjust the start date to the beginning of the week
        start.setDate(start.getDate() - start.getDay());

        const weeks = [];
        let current = new Date(start);

        while (current < today) {
            const weekStart = new Date(current);
            const weekEnd = new Date(current);
            weekEnd.setDate(weekEnd.getDate() + 6);
            weeks.push({ start: weekStart, end: weekEnd });
            current.setDate(current.getDate() + 7);
        }

        return weeks;
    }

    getMonthDay(date, index) {
        if (index == 0) {
            var start_date = moment().format('YYYY-MM-DD').toString()
        } else {
            start_date = moment(date.toString()).add(-6, 'months').format('YYYY-MM-DD').toString()
        }
        return [start_date];
    }

    getFutureDay(date, index) {
        if (index == 0) {
            var start_date = moment(date).format('YYYY-MM-DD').toString();
        } else {
            // console.log(date);
            let todayDate = new Date();
            let todayDate_month = todayDate.getMonth() + 1;
            let todayDate_year = todayDate.getFullYear().toString();
            let compare_date = moment(date.toString()).add(6, 'months').format('DD-M-YYYY').toString()
            // console.log(compare_date);
            let date_year = compare_date.split('-')[2];
            let date_month = compare_date.split('-')[1];

            if (date_year == todayDate_year && date_month <= todayDate_month.toString()) {
                start_date = moment(date.toString()).add(6, 'months').format('YYYY-MM-DD').toString()
            } else if (date_year < todayDate_year) {
                start_date = moment(date.toString()).add(6, 'months').format('YYYY-MM-DD').toString()
            } else {
                start_date = moment(todayDate.toString()).format('YYYY-MM-DD').toString()
            }
        }
        return [start_date];
    }

    findnearest(value) {
        value = Math.abs(Math.ceil(value));
        let length = value.toString().length;
        if (length === 1) {
            return length;
        } else {
            var test1 = "1";
            for (let index = 0; index < length - 1; index++) {
                test1 = test1 + "0";
            }
            var test2 = value % Number(test1);
            var test3 = value - test2;
            var test4: any = test2.toString().length === length - 1 ? Number(test2.toString()[0]) + 1 : 1;
            for (let index = 0; index < length - 2; index++) {
                test4 = test4 + "0";
            }
            var final = Number(test3) + Number(test4);
            return final
        }

    }
}


