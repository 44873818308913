import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { apiColletions } from "../constants/api_collection";
import { UserModel } from "../models/user.model";
import { Helper } from "../shared/helper";
import { ApiService } from "./api.service";
import { getUserRole } from 'src/app/utils/util';

@Injectable({ providedIn: 'root' })
export class AuthService {

    public logginUser: any;
    public user_details = JSON.parse(localStorage.getItem('userData'));
    private loginSubject = new BehaviorSubject<any>(null);
    loginObservable: Observable<UserModel> = this.loginSubject.asObservable();
    private permissions = new BehaviorSubject<any>(null);
    authPermission = this.permissions.asObservable();
    is_main_store_login;


    get isAuthenticated(): boolean {
        return (!this.logginUser || this.logginUser === null) ? false : true;
    }

    get user_detail() {
        return this.logginUser ? this.logginUser : new UserModel();
    }

    constructor(private _api: ApiService, private helper: Helper) { }

    // Login

    login(parameters): Promise<boolean> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.login, parameters }).then((response) => {
                    if (response.success) {
                        this.logginUser = response.data.adminDetail;
                        var localStorageData = {
                            _id: this.logginUser._id,
                            username: this.logginUser.username,
                            token: this.logginUser.token,
                            country: this.logginUser.country,
                            email: this.logginUser.email,
                            // url_array: this.logginUser.url_array
                        }
                        if (response.data.adminDetail.type === 1) {
                            this.helper.user_details = localStorageData;
                            this.subStoreSignIn(response.data.adminDetail.url_array)
                            this.is_main_store_login = false;
                        } else {
                            this.is_main_store_login = true;
                            this.helper.user_details = localStorageData;
                            localStorage.setItem('userData', JSON.stringify(localStorageData))
                            this.loginSubject.next(this.logginUser);
                        }
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
            } catch (err) {
                resolve(true);
            }
        })
    }

    logout(parameters): Promise<boolean> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.logout, parameters }).then((res) => {

                    if (res.success) {
                        this.logginUser = null;
                        this.loginSubject.next(this.logginUser);
                        localStorage.removeItem('userData');
                        localStorage.removeItem('adminPermissions');
                        this.helper.isUpadtedlocalStorage();
                        this.helper._route.navigate(['/admin/login'])
                        resolve(true)
                    } else {
                        rejects(false)
                    }
                })
            } catch (error) {
                resolve(false);
            }
        })
    }

    // Forgot Password

    forgot_password_Email(parameters): Promise<boolean> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.forgot_password, parameters }).then((response) => {
                    if (response.success) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
            } catch (err) {
                resolve(false);
            }
        })
    }

    new_password(parameters): Promise<boolean> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.new_password, parameters }).then((response) => {
                    if (response.success) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
            } catch (err) {
                resolve(false);
            }
        })
    }

    autologin() {
        this.logginUser = JSON.parse(localStorage.getItem('userData'));
        if (this.logginUser && this.logginUser.token) {
            var adminPermissions = JSON.parse(localStorage.getItem('adminPermissions'));
            if (adminPermissions) {
                this.is_main_store_login = false;
                this.permissions.next(adminPermissions)
            } else {
                this.is_main_store_login = true;
            }
            this.loginSubject.next(this.logginUser);
        } else {
            this.logginUser = null;
            this.loginSubject.next(this.logginUser);
            localStorage.removeItem('userData');
            localStorage.removeItem('adminPermissions');
            this.helper.isUpadtedlocalStorage();
            this.helper._route.navigate(['/admin/login'])
        }
    }

    subStoreSignIn(urls) {
        const permissions = urls;
        this.permissions.next(permissions)
        this.is_main_store_login = false;
        localStorage.setItem('userData', JSON.stringify(this.logginUser));
        localStorage.setItem('adminPermissions', JSON.stringify(permissions));
        this.loginSubject.next(this.logginUser);
    }

    async getUser() {
        return { displayName: this.logginUser.username, role: getUserRole() };
    }

}
