// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { UserRole } from '../app/shared/auth.roles';

export const environment = {
  production: false,

  API_URL: 'https://srena-api.gen-tech.cc/admin',
  HISTORY_API_URL: 'https://srena-history.gen-tech.cc',
  IMAGE_URL: 'https://srena-api.gen-tech.cc/',
  BASE_URL: 'https://srena-api.gen-tech.cc/',
  SOCKET_URL: 'https://srena-api.gen-tech.cc/',

  CLOUDFLARE_CAPTCHA_SITEKEY: '0x4AAAAAAAjraNulkqIVSW8f',
  GOOGLE_ANALYTICS_TRACKING_ID: 'G-DKERN6RQCL',

  GOOGLE_KEY: 'AIzaSyCKxsYn1eXPw8KuBgt2KDi88WKkmIPTnLI',
  buyUrl: 'https://1.envato.market/6NV1b',
  SCARF_ANALYTICS: false,
  adminRoot: '/app',
  apiUrl: 'https://api.coloredstrategies.com',
  defaultMenuType: 'menu-default',
  subHiddenBreakpoint: 1442,
  menuHiddenBreakpoint: 768,
  themeColorStorageKey: 'vien-themecolor',
  isMultiColorActive: true,
  defaultColor: 'light.blueyale',
  isDarkSwitchActive: true,
  defaultDirection: 'ltr',
  themeRadiusStorageKey: 'vien-themeradius',
  isAuthGuardActive: true,
  defaultRole: UserRole.Admin,
  firebase: {
    apiKey: "AIzaSyDWmWqF3oSd0VieebEmLPoc6QyP6x6iH5Y",
    authDomain: "eber-taxi.firebaseapp.com",
    databaseURL: "https://eber-taxi.firebaseio.com",
    projectId: "eber-taxi",
    storageBucket: "eber-taxi.appspot.com",
    messagingSenderId: "291342805628",
    appId: "1:291342805628:web:f2be602597943ba9",
    measurementId: "G-42M1R08LYK"
  }
};
