export var apiColletions = {

    //dashboard_detail
    "dashboard_detail" : "/dashboard_detail",
    "get_six_month_earning" : "/get_six_month_earning",
    "get_six_month_trip":"/get_six_month_trip",


    //refund 
    "refund_trip_amount":"/refund_trip_amount",

    //driver 
    "unfreeze_provider":"/unfreeze_provider",
    "add_provider_vehicle":"/add_provider_vehicle",
    "type_is_approved":"/type_is_approved",

    //earning
    "trip_earning":"/trip_earning",
    "trip_earning_statement":"/statement_provider_trip_earning",
    "weekly_and_daily_earning":"/weekly_and_daily_earning",
    "statement_provider_daily_and_weekly_earning":"/statement_provider_daily_and_weekly_earning",
    "admin_partner_weekly_earning":"/partner_weekly_earning",
    "admin_partner_weekly_earning_statement":"/partner_weekly_earning_statement",
    "admin_wallet_history":"/wallet_history",
    "admin_transaction_history":"/transaction_history",

    //service-type
    "service_type_list":"/get_type_list",
    "add_service_type":"/add_service_type",
    "edit_service_type":"/edit_service_type",
    "fetch_service_type":"/fetch_service_type",
    
    //coutry
    "get_All_country_list":"/get_country_json_list",
    "fetch_country_details":"/fetch_country_details",
    "check_country_exists":"/check_country_exists",
    "add_country_details":"/add_country_details",
    "update_country_details":"/update_country_details",
    
    //city
    "admin_get_city_list":"/fetch_city_list",
    "admin_add_city_details":"/add_city_details",
    "admin_update_city_details":"/update_city_details",
    "admin_fetch_destination_city":"/fetch_destination_city",
    "admin_check_city_avaliable":"/check_city_avaliable",

    //city-type assocation
    "admin_fetch_service_price":"/fetch_service_price",
    "admin_fetch_unique_types":"/fetch_unique_types",
    "update_service_price":"/update_service_price",
    "add_service_price":"/add_service_price",

    //all zone detail
    "admin_fetch_airport_details":"/fetch_airport_details",
    "admin_fetch_cityzone_details":"/fetch_cityzone_details",
    "admin_fetch_redzone_details":"/fetch_redzone_details",
    "admin_update_zone_details":"/update_zone_details",
    "admin_update_airport_details":"/update_airport_details",
    "admin_update_redzone_details":"/update_redzone_details",

    //zone price 
    "admin_fetch_airport_price":"/fetch_airport_price",
    "admin_fetch_zone_price":"/fetch_zone_price",
    "admin_fetch_city_price":"/fetch_city_price",
    "check_zone_price_exist":"/check_zone_price_exist",

    //rental price 
    "admin_fetch_car_rental":"/fetch_car_rental",

    //rich surge 
    "admin_fetch_rich_surge":"/fetch_rich_surge",

    //update surge hour
    "update_surge_hour":"/update_surge_hour",

    //Common
    "get_countries": "/country_list",
    "get_user_setting_detail": "/get_user_setting_detail",
    "get_country_timezone":"/get_country_timezone",
    
    // auth
    "login":"/login",
    "logout":"/sign_out",
    "forgot_password":"/forgot_password",
    "new_password":"/update_password",

    //sub-admin
    "admin_url_list":"/url_list",
    "add_new_admin":"/add_new_admin",
    "admin_list":"/list",
    "update_admin_details":"/update_admin_details",
    "delete_admin":"/delete_admin",

    //map view
    "provider_list_for_map":"/provider_list_for_map",
    "fetch_vehicle_type_list":"/fetch_vehicle_type_list",
    "fetch_provider_list":"/fetch_provider_list",
    "fetch_all_city":"/fetch_all_city",

    //mass notification
    "fetch_notification_list":"/fetch_notification_list",
    "send_mass_notification":"/send_mass_notification",

    //review
    "reviews_list":"/reviews_list",

    //settings
    "admin_get_setting_details":"/get_setting_details",
    "admin_update_setting_details":"/update_setting_details",
    "admin_upload_logo_images":"/upload_logo_images",
    "uplodad_user_panel_images":"/uplodad_user_panel_images",

    //language
    "get_language_list":"/get_language_list",
    "add_new_language":"/add_new_language",
    "edit_language":"/edit_language",
    "delete_language":"/delete_language",

    //request
    "get_trip_list":"/history/get_trip_list",
    "get_trip_detail":"/get_trip_detail",
    "trip_cancel_by_admin":"/trip_cancel_by_admin",
    "trip_complete_by_admin":"/trip_complete_by_admin",
    "scheduled_trip_cancel_by_admin":"/scheduled_trip_cancel_by_admin",
    "chat_history":"/chat_history",
    "generate_firebase_access_token":"/generate_firebase_access_token",

    //promocode
    "admin_fetch_promo_list":"/fetch_promo_list",
    "admin_promo_used_info":"/promo_used_info",
    "admin_add_promo":"/add_promo",
    "admin_delete_promocode":"/delete_promocode",
    "admin_update_promo_details":"/update_promo_details",

    //document
    "admin_get_document_list":"/get_document_list",
    "admin_add_document_details":"/add_document_details",
    "admin_update_document_details":"/update_document_details",

    //sms setting
    "fetch_sms_details":"/fetch_sms_details",
    "update_sms_details":"/update_sms_details",

    //email setting
    "admin_get_email_title":"/get_email_title",
    "admin_fetch_email_detail":"/fetch_email_detail",
    "update_email_detail":"/update_email_detail",

    // user and driver list
    "get_user_detail_list" : "/user_details_list",
    
    // Filter list api  
    "admin_all_type_list" : "/fetch_type_list",

    // Edit_data_api
    "fetch_type_details" : "/fetch_type_details",
    "service_type_trip_list" : "/service_type_trip_list",

    // Update List By Type 

    "update_type_details" : "/update_type_details",
    
    // Delete List Item Data 

    "delete_type_details" : "/delete_type_details",

    // Add Amount in Wallet 

    "add_wallet_amount" : "/add_wallet_amount",

    // Vehicle Document List

    "fetch_document_list" : "/fetch_document_list",
    "update_document_details" : "/type_update_document",
    "type_update_vehicle" : "/type_update_vehicle", 

    // Add New By Type 

    "add_new_type" : "/add_new_type",

    // Referral History

    "fetch_referral_list" : "/fetch_referral_list",
    "referral_list" : "/referral_list",
    "referral_details" : "/referral_details",

    //Export History
    "get_export_history_list":"/history/get_export_history_list",
    "delete_export_file":"/history/delete_export_file"
    
}