export var PDFSIZE = 100000;

export var DEFAULT_IMAGE = {
	DEFAULT_PDF_IMG: 'assets/default_images/pdf_img.png',
	DEFAULT_TAXI_IMAGE: 'assets/img/Taxi/Taxi.jpg',
	USER_PROFILE: 'assets/default_images/user.png',
	USER_SQUARE: 'assets/default_images/user_square.png',
	DOCUMENT_PROFILE: 'assets/default_images/document_default.png',
	PICKUP_ICON: 'assets/default_images/map_pin/pickup.png',
	DESTINATION_ICON: 'assets/default_images/map_pin/destination.png',
	DRIVER_ICON: 'assets/default_images/map_pin/driver.png',
	STOP_ICON: 'assets/default_images/map_pin/stops.png',
}
export var DATE_FORMAT = {
	DD_MM_YYYY_HH_MM_A: 'dd MMM yyyy hh:mm a',
	DD_MM_YYYY: 'dd MMM yyyy',
	DD_MM_YY: "DD MMM YY",
	MOMENT_DD_MMM_YYYY: "DD MMM YYYY",
	D_MMM_H_MM_A:"d MMM yy - h:mm a",
	dd_mm_yyyy:'dd/MM/yyyy',
	medium:'medium',
	HH_MM_A:"hh:mm a",
	DASHED_DD_MM_YYYY:"DD-MM-YYYY",
	MMM_YYYY:"MMM-yyyy"
}
export var VEHICLE_RATIO = {
	vehicle_image_ratio: 1.25,
	vehicle_image_max_width: 500,
	vehicle_map_pin_ratio:0.45,
	vehicle_map_pin_max_width:90
}

export var USER_PANEL_RATIO = {
	bg_image_ratio: 1.50,
	bg_image_max_width: 1920,
	image_ratio: 1.59,
	image_max_width: 900,
	icon_image_ratio: 1,
	icon_image_max_width: 180,
}

export var TRIP_TYPE = {
	TRIP_TYPE_NORMAL: 0,
	TRIP_TYPE_VISITOR: 1,
	TRIP_TYPE_HOTEL: 2,
	TRIP_TYPE_DISPATCHER: 3,
	TRIP_TYPE_SCHEDULE: 5,
	TRIP_TYPE_PROVIDER: 6,
	TRIP_TYPE_CORPORATE: 7,

	TRIP_TYPE_AIRPORT: 11,
	TRIP_TYPE_ZONE: 12,
	TRIP_TYPE_CITY: 13,
	TRIP_TYPE_CAR_RENTAL: 14,
	TRIP_TYPE_GUEST_TOKEN: 15,
}

export var SPLIT_PAYMENT = {
	WAITING: 0,
	ACCEPTED: 1,
	REJECTED: 2
}

export var DOMAIN = {
	1: "gmail",
	2: "other"
}

export var CERTIFICATE_MODE = {
	1: "sandbox",
	2: "production"
}

export var PANEL_TYPE  = {
	USER : '1',
	PROVIDER : '2',
	PARTNER : '3',
	CORPORATE : '4',
	HOTEL : '5' ,
	DISPATCHER : '6'
}

export var PANEL_NAME  = {
	USER : 'Customer',
	PROVIDER : 'Provider',
	PARTNER : 'Partner',
	CORPORATE : 'Corporate',
	HOTEL : 'Hotel' ,
	DISPATCHER : 'Dispatcher'
}

export var PROMO_CODE = {
	RUNNING: '1',
	INACTIVE: '2',
	EXPIRED: '3',
}

export var TRIP_STATUS = {
	RUNNING: 1,
	SCHEDULED: 2,
	COMPLETED: 3,
	CANCELLED: 4
}

export var PROVIDER_STATUS = {
	WAITING: 0,
	ACCEPTED: 0,
	ACCEPT: 1,
	COMING: 2,
	AFTER_TIME_WAITING: 3,
	ARRIVED: 4,
	STARTED: 6,
	COMPLETED: 9
}

export var PROVIDER_ACCEPTED = {
	WAITING: 0,
	ACCEPTED: 1,
	AFTER_TIME_WAITING: 3,
}

export var PER_PAGE_LIST = [20,50,100]
export var USERS_PER_PAGE_LIST = [15,30,60]

export var EXPORT_HISTORY_STATUS = {
	QUEUED: 0,
    COMPLETED: 1,
    FAILED: 2
}