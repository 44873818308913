import { Component, OnInit, Renderer2, AfterViewInit } from '@angular/core';
import { LangService } from './shared/lang.service';
import { AuthService } from './services/auth.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

@Injectable()
export class AppComponent implements OnInit, AfterViewInit {
  isMultiColorActive = environment.isMultiColorActive;
  constructor(
    private langService: LangService,
    private renderer: Renderer2,
    private _authService: AuthService,
    private router: Router,  // Inject Router
    private googleAnalyticsService: GoogleAnalyticsService // Inject GoogleAnalyticsService
  ) {

  }

  ngOnInit(): void {
    this.googleAnalyticsService.loadGoogleAnalytics();
    // Track route changes for Google Analytics
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.googleAnalyticsService.trackPageView(event.urlAfterRedirects);
      }
    });
    this.langService.init();
    this._authService.autologin();
    this.setFavicon();
  }

  setFavicon(): void {
    const favicon: HTMLLinkElement = document.querySelector("link[rel*='icon']") || document.createElement('link');
    favicon.type = 'image/x-icon';
    favicon.rel = 'icon';
    favicon.href = `${environment.IMAGE_URL}web_images/logo.png`;
    document.getElementsByTagName('head')[0].appendChild(favicon);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.renderer.addClass(document.body, 'show');
    }, 1000);
    setTimeout(() => {
      this.renderer.addClass(document.body, 'default-transition');
    }, 1500);
  }
}
